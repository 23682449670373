import React from "react";
import ContactMap from "./sections/map";
import JoinWaitlist from "../../common/joinWaitlist";
import ContactHero from "./sections/hero";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - | Slayhop.com</title>
      </Helmet>
      <ContactHero />
      <ContactMap />
      <JoinWaitlist />
    </>
  );
};

export default ContactUs;
