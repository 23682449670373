import React from "react";
import ResponsivePagination from "react-responsive-pagination";
import "./Pagination.scss";
import { next, prev } from "../../../../../common/images";

const Pagination = ({ total, currentPage, pageSize, handlePageChange }) => {
  const totalPages = Math.ceil(total / pageSize);

  return (
    <ResponsivePagination
      total={totalPages}
      current={currentPage}
      onPageChange={(page) => handlePageChange(page)}
      previousLabel={
        <div className="pagination-label">
          <img src={prev} alt="prev-arrow" />
          <span>Previous</span>
        </div>
      }
      nextLabel={
        <div className="pagination-label">
          <span>Next</span>
          <img src={next} alt="next-arrow" />
        </div>
      }
    />
  );
};

export default Pagination;
