/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './style.scss';
import {
  discord,
  footerBadge,
  instagramFooter,
  logo,
  tiktok,
  twitter,
} from '../../common/images';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';

const Footer = () => {
  return (
    <footer className="section footer">
      <div className="container">
        <div className="footer-content">
          <div className="left">
            <img src={logo} alt="logo" />
            <p>
              Build a massive following. Go viral and stay viral. Your TikTok
              success story starts now!
            </p>
            <img src={footerBadge} alt="footer-badge" />
          </div>
          <div className="right">
            <div className="links">
              <h3>Pages</h3>
              <Link to="/" onClick={scrollToTop}>
                Our App
              </Link>
              <Link to="/contact-us" onClick={scrollToTop}>
                Contact Us
              </Link>
              <Link to="/blog" onClick={scrollToTop}>
                Our Blog
              </Link>
            </div>
            <div className="links">
              <h3>Social</h3>
              <a
                href="https://www.instagram.com/slayhopofficial?igsh=cjFwY3VrcWo0NXdw&utm_source=qr "
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://x.com/slayhopofficial "
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://www.tiktok.com/@slayhopofficial?_t=8pcPPbrkvkM&_r=1 "
                target="_blank"
                rel="noopener noreferrer"
              >
                TikTok
              </a>
              <a
                href="https://whop.com/slayhop-community/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </div>
            <div className="links">
              <h3>Legal</h3>
              <Link to="privacy-policy" onClick={scrollToTop}>
                Privacy Policy
              </Link>
              <Link to="/terms-conditions" onClick={scrollToTop}>
                Terms & Conditions
              </Link>
              <Link to="cookies-policy" onClick={scrollToTop}>
                Cookies Policy
              </Link>
              <Link to="refund-policy" onClick={scrollToTop}>
                Refund Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="copyright">
            <p>© {new Date().getFullYear()} Slayhop. All rights reserved.</p>
          </div>
          <div className="social-icons">
            <a
              href="https://x.com/slayhopofficial "
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.tiktok.com/@slayhopofficial?_t=8pcPPbrkvkM&_r=1 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktok} alt="tiktok" />
            </a>
            <a
              href="https://www.instagram.com/slayhopofficial?igsh=cjFwY3VrcWo0NXdw&utm_source=qr "
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramFooter} alt="instagram" />
            </a>
            <a
              href="https://whop.com/slayhop-community/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={discord} alt="discord" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
