import { Suspense, lazy, useState } from 'react';
import './App.css';
import Footer from './layout/footer';
import Header from './layout/header';
import { Route, Routes } from 'react-router-dom';
import SkeletonLoader from './common/loader';
import NotFound from './pages/notFound';
import Blog from './pages/blog';
import ContactUs from './pages/contact';
import Privacy from './pages/privacy';
import CookiesPolicy from './pages/cookies';
import RefundPolicy from './pages/refund';
import Terms from './pages/terms';
import Detail from './pages/blog/detail';

// Lazy load the components
const Home = lazy(() => import('./pages/home'));
// const TermsConditions = lazy(() => import("./pages/terms"));
// const Privacy = lazy(() => import("./pages/privacy"));

function App() {
  const [overlay, setOverlay] = useState(false);

  return (
    <>
      <Header setOverlay={setOverlay} />
      <div className={`main-content ${overlay ? 'overlay' : ''}`}>
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  {' '}
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/blog"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <Blog />
                </Suspense>
              }
            />
            <Route
              path="/blog-detail/:id"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  {' '}
                  <Detail />{' '}
                </Suspense>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <ContactUs />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <Privacy />
                </Suspense>
              }
            />
            <Route
              path="/cookies-policy"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <CookiesPolicy />
                </Suspense>
              }
            />
            <Route
              path="/refund-policy"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <RefundPolicy />
                </Suspense>
              }
            />
            <Route
              path="/terms-conditions"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <Terms />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;
