// SkeletonLoader.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";

const SkeletonLoader = () => {
  return (
    <div className="skeleton-loader">
      <Skeleton className="mbl-width first" />
      <Skeleton className="mbl-width second" />
      <Skeleton className="mbl-width third" />
      <Skeleton className="mbl-width fourth" />
      <Skeleton count={3} />
    </div>
  );
};

export default SkeletonLoader;
