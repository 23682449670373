import React from "react";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions - | Slayhop.com</title>
      </Helmet>
      <section className="section terms">
        <div className="container">
          <div className="heading">
            <span>Last Updated: 21.03.2023</span>
            <h2>Terms and Conditions</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="content">
          <p>
            <strong>1. AGREEMENT TO TERMS</strong>
          </p>
          <p>
            (1) These Terms of Service constitute a legally binding agreement
            made between you, whether personally or on behalf of an entity
            (“you”) and Slayhop INC, with the address 4730 S. FORT APACHE RD
            SUITE 300 , doing business as Slayhop.com (“Slayhop.com“, “we”,
            “us”, or “our”), concerning your access to and use of the
            https://slayhop.com as well as any other media form, media channel,
            mobile website or mobile application related, linked, or otherwise
            connected thereto (collectively, the “Site”).
          </p>
          <p>
            (2) You agree that by accessing the Site, you have read, understood,
            and agree to be bound by all of these Terms of Service. If you do
            not agree with all of these terms of use, then you are expressly
            prohibited from using the site and you must discontinue use
            immediately.{" "}
          </p>
          <p>
            (3) Supplemental terms and conditions or documents that may be
            posted on the Site from time to time are hereby expressly
            incorporated herein by reference. We reserve the right, in our sole
            discretion, to make changes or modifications to these Terms of Use
            at any time and for any reason. We will alert you about any changes
            by updating the “Last updated” date of these Terms of Use, and you
            waive any right to receive specific notice of each such change. It
            is your responsibility to periodically review these Terms of Use to
            stay informed of updates. You will be subject to, and will be deemed
            to have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.{" "}
          </p>
          <p>
            (4) The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.{" "}
          </p>
          <br />
          <br />
          <p>
            <strong>2. DEFINITIONS</strong>
          </p>
          <p>
            (1) The following capitalized terms shall have the meanings set
            forth below unless otherwise expressly provided:
            <br />
            <br />
            “Seller” or “Provider” means Slayhop.com, a limited liability
            company as identified in Section 1.1
            <br />
            <br />
            “Products” means any goods (products) or services that are offered
            to the Customer for purchase, against payment, through the Platform.
            <br />
            <br />
            “Customer” means the natural or legal person who accesses the
            Platform and places an order for the purchase of products offered
            for sale by the Seller.
            <br />
            <br />
            “Contract” means the consensual remote agreement between the Seller
            and the Customer, without their simultaneous physical presence,
            regarding the sale, respectively purchase, of one or more Products
            on the Platform, through the placing of an Order by the Customer and
            its acceptance by the Seller, in compliance with the legal
            provisions and the terms and conditions for the online sale of the
            Seller’s services and products.
            <br />
            <br />
            “Platform” means the website/websites owned or used by the Seller,
            including pages and profiles on social networks, having the domain
            slayhop.com and its subdomains or affiliated websites and, if
            applicable, their subdomains and using the Seller’s logos, through
            which the Seller presents the Products offered for sale and
            Customers can choose the Products they wish to purchase,
            respectively pay for them in one of the payment methods accepted by
            the Seller.
            <br />
            <br />
            “Products” means the digital services available on the Platform,
            which can be purchased by the Customer;
            <br />
            <br />
            “Specifications” means any details of the characteristics of the
            Products as specified in the description available on the Platform.
            <br />
            <br />
            “Transaction” means the payment operation carried out by the
            Customer, respectively the collection by the Seller, of a sum of
            money as a result of the sale by the Seller, respectively the
            purchase by the Customer, of one or more Products.
            <br />
            <br />
            (2) This document sets out the terms and conditions applicable to
            the relationship between the Seller and each Customer using the
            Platform for, on the occasion of or after placing an Order.
            <br />
            <br />
            (3) Headings are included in this document for ease of reading and
            structuring of the document and shall not affect its interpretation.
            <br />
            <br />
            (4) For the avoidance of doubt, in accordance with the business
            policy established independently and unilaterally by the Seller, the
            sale online (i.e. via the Platform) of the Products normally
            marketed by the Seller shall be made to consumers who are natural
            persons or to legal entities. If certain Products are limited to a
            certain category of Customers only, this shall be specified in the
            Specifications or in another visible place next to the Product.
            <br />
            <br />
            (5) Privacy and personal data processing issues related to the use,
            in any form, of the Platform are governed by the privacy
            policy/information notice (“Privacy Policy”) which supplements these
            Terms and Conditions.
          </p>
          <br />
          <p>
            <strong>3. INTELLECTUAL PROPERTY RIGHTS</strong>
          </p>
          <p>
            (1) Unless otherwise indicated, the Site is our proprietary property
            and all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </p>
          <p>
            (2) Provided that you are eligible to use the Site, you are granted
            a limited license to access and use the Site and to download or
            print a copy of any portion of the Content to which you have
            properly gained access solely for your personal, non-commercial use.
            We reserve all rights not expressly granted to you in and to the
            Site, the Content and the Marks.
            <br />
            <br />
          </p>
          <p>
            <strong>4. USER REPRESENTATIONS</strong>
          </p>
          <p>
            (1) By using the Site, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Terms
            of Use; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Site through automated or
            non-human means, whether through a bot, script or otherwise; (6) you
            will not use the Site for any illegal or unauthorized purpose; and
            (7) your use of the Site will not violate any applicable law or
            regulation.
          </p>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
            <br />
            <br />
          </p>
          <p>
            <strong>5. USER REGISTRATION</strong>
          </p>
          <p>
            (1) You may be required to register with the Site. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
          <br />
          <br />
          <p>
            <strong>6. FEES AND PAYMENT</strong>
          </p>
          <p>(1) We accept the following forms of payment:</p>
          <ul>
            <li>
              <p>Visa</p>
            </li>
            <li>
              <p>Mastercard</p>
            </li>
            <li>
              <p>American Express</p>
            </li>
            <li>
              <p>Discover</p>
            </li>
          </ul>
          <p>
            (2) You may be required to purchase or pay a fee to access some of
            our services. You agree to provide current, complete, and accurate
            purchase and account information for all purchases made via the
            Site. You further agree to promptly update account and payment
            information, including email address, payment method, and payment
            card expiration date, so that we can complete your transactions and
            contact you as needed. We bill you through an online billing account
            for purchases made via the Site. Sales tax will be added to the
            price of purchases as deemed required by us. We may change prices at
            any time. All payments shall be in U.S. dollars.
          </p>
          <p>
            (3) You agree to pay all charges or fees at the prices then in
            effect for your purchases, and you authorize us to charge your
            chosen payment provider for any such amounts upon making your
            purchase. If your purchase is subject to recurring charges, then you
            consent to our charging your payment method on a recurring basis
            without requiring your prior approval for each recurring charge,
            until you notify us of your cancellation.
          </p>
          <p>
            (4) We reserve the right to correct any errors or mistakes in
            pricing, even if we have already requested or received payment. We
            also reserve the right to refuse any order placed through the Site.
          </p>
          <p>
            (5) An invoice shall be issued for the Products purchased, and the
            Customer shall be obliged to provide all information required under
            applicable law for the Company to issue the invoice, requesting, in
            this regard, that care be taken when entering data.
          </p>
          <br />
          <br />
          <p>
            <strong>
              7. PLATFORM USAGE POLICY. MAIN FEATURES OF ONLINE SELLING BY THE
              SELLER.
            </strong>
          </p>
          <p>
            (1) Access to the Platform for the purpose of placing an Order is
            permitted to any potential Customer acting with a legitimate purpose
            and intending to purchase one or more Products from us, subject to
            these terms and conditions.
          </p>
          <p>
            (2) By Customer we mean a legal or natural person who is at least 18
            years old.
          </p>
          <p>
            (3) Misuse of the Platform is strictly prohibited. Misuse is any use
            of the Platform in a way that is contrary to fair business practice,
            applicable law or in any other way that would be detrimental to the
            Company in any way.
          </p>
          <p>
            (4) The Company reserves the right to refuse access to part or all
            of the Platform’s functions and to restrict the processing and/or
            delivery of an Order if, in the Company’s reasonable opinion, there
            is suspicion of fraud on the part of the prospective Customer, or if
            the prospective Customer’s conduct may harm our interests.
          </p>
          <p>
            (5) By using the Platform and, where applicable, registering an
            Order, the form of remote communication through which the
            transactions are carried out is accepted.
          </p>
          <p>
            (6) All information used for the description of the Products
            (including, but not limited to, static or dynamic images,
            descriptive text, graphic or video presentations) does not impose
            any obligation on the Company, as it is for presentation and
            information purposes only. The Company shall use all reasonable
            endeavours to ensure the accuracy of the pricing information,
            Products and their Specifications presented on the Platform.
          </p>
          <p>
            (7) Products that are sold as part of a promotion or campaign of any
            kind shall also be subject to the terms and conditions applicable to
            such campaign or promotion, which we will bring to your attention.
          </p>
          <p>
            (8) Any promotions presented on the Platform are valid for the
            duration mentioned. Where no duration is indicated for the running
            of promotions, they will be valid within the limits of available
            stocks/places or for such duration as the Company deems appropriate
            to achieve our objectives.
          </p>
          <p>
            (9) The Order is accepted and the Contract is deemed to be concluded
            between the Company and the Customer when the Customer receives a
            notification of dispatch (by phone call/SMS/e-mail/push notification
            or otherwise) of the Products in the Order.
          </p>
          <p>
            (10) It should be noted that the Contract forms part of this
            document (together with all other documents referred to), as well as
            any subsequent agreements between the Company and the Customer in
            relation to the Order, regardless of the manner in which they are
            made.
          </p>
          <p>
            (11) The Company may generally be contacted at the email address in
            the “Contact” section of the Platform for any queries or issues in
            relation to the Order, including to identify and correct any errors
            that occur during data entry.
          </p>
          <p>
            (12) For communication purposes, only official channels of such
            nature that we indicate (i.e. e-mail) will be considered when the
            Company processes a request.
          </p>
          <br />
          <br />
          <p>
            <strong>8. SUBMISSIONS</strong>
          </p>
          <p>
            (1) You acknowledge and agree that any questions, comments,
            suggestions, ideas, feedback, or other information regarding the
            Site (“Submissions“) provided by you to us are non-confidential and
            shall become our sole property. We shall own exclusive rights,
            including all intellectual property rights, and shall be entitled to
            the unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>
          <br />
          <br />
          <p>
            <strong>9. SITE MANAGEMENT</strong>
          </p>
          <p>
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems;
            and (5) otherwise manage the Site in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Site.
          </p>
          <br />
          <br />
          <p>
            <strong>10. PRIVACY POLICY</strong>
          </p>
          <p>
            (1) We care about data privacy and security. By using the Site, you
            agree to be bound by our Privacy Policy posted on the Site, which is
            incorporated into these Terms of Use. Please be advised the Site is
            hosted in the United States. If you access the Site from any other
            region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from
            applicable laws in the United States, then through your continued
            use of the Site, you are transferring your data to the United
            States, and you agree to have your data transferred to and processed
            in the United States.
          </p>
          <br />
          <br />
          <p>
            <strong>11. TERM AND TERMINATION</strong>
          </p>
          <p>
            (1) These Terms of Use shall remain in full force and effect while
            you use the Site. Without limiting any other provision of these
            terms of use, we reserve the right to, in our sole discretion and
            without notice or liability, deny access to and use of the site
            (including blocking certain ip addresses), to any person for any
            reason or for no reason, including without limitation for breach of
            any representation, warranty, or covenant contained in these terms
            of use or of any applicable law or regulation. We may terminate your
            use or participation in the site or delete your account and any
            content or information that you posted at any time, without warning,
            in our sole discretion.
          </p>
          <p>
            (2) If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
          <br />
          <br />
          <p>
            <strong>12. MODIFICATIONS AND INTERRUPTIONS</strong>
          </p>
          <p>
            (1) We reserve the right to change, modify, or remove the contents
            of the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site.
          </p>
          <p>
            (2) We cannot guarantee the Site will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Site, resulting in interruptions,
            delays, or errors. We reserve the right to change, revise, update,
            suspend, discontinue, or otherwise modify the Site at any time or
            for any reason without notice to you. You agree that we have no
            liability whatsoever for any loss, damage, or inconvenience caused
            by your inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.
          </p>
          <br />
          <br />
          <p>
            <strong>13. GOVERNING LAW</strong>
          </p>
          <p>
            (1) These Terms of Use and your use of the Site are governed by and
            construed in accordance with the laws of the Nevada State, USA.
          </p>
          <br />
          <br />
          <p>
            <strong>14. DISPUTE RESOLUTION</strong>
          </p>
          <p>Informal Negotiations</p>
          <br />
          <p>
            (1) To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Use (each a
            “Dispute” and collectively, the “Disputes”) brought by either you or
            us (individually, a “Party” and collectively, the “Parties”), the
            Parties agree to first attempt to negotiate any Dispute (except
            those Disputes expressly provided below) informally for at least
            thirty (30) days before initiating arbitration. Such informal
            negotiations commence upon written notice from one Party to the
            other Party.
          </p>
          <br />
          <p>Binding Arbitration</p>
          <br />
          <p>
            (2) If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. You understand that without this provision, you would
            have the right to sue in court and have a jury trial. The arbitrator
            must follow applicable law, and any award may be challenged if the
            arbitrator fails to do so. Except as otherwise provided herein, the
            Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>
          <p>
            (3) In no event shall any Dispute brought by either Party related in
            any way to the Site be commenced more than one (1) years after the
            cause of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <br />
          <p>Restrictions</p>
          <br />
          <p>
            (4) The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>
          <br />
          <p>
            (4) The Parties agree that the following Disputes are not subject to
            the above provisions concerning informal negotiations and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <br />
          <br />
          <p>
            <strong>15. CORRECTIONS</strong>
          </p>
          <p>
            (1) There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>
          <br />
          <br />
          <p>
            <strong>16. DISCLAIMER</strong>
          </p>
          <p>
            The site is provided on an as-is and as-available basis. You agree
            that your use of the site and our services will be at your sole
            risk. To the fullest extent permitted by law, we disclaim all
            warranties, express or implied, in connection with the site and your
            use thereof, including, without limitation, the implied warranties
            of merchantability, fitness for a particular purpose, and
            non-infringement. We make no warranties or representations about the
            accuracy or completeness of the site’s content or the content of any
            websites linked to the site and we will assume no liability or
            responsibility for any (1) errors, mistakes, or inaccuracies of
            content and materials, (2) personal injury or property damage, of
            any nature whatsoever, resulting from your access to and use of the
            site, (3) any unauthorized access to or use of our secure servers
            and/or any and all personal information and/or financial information
            stored therein, (4) any interruption or cessation of transmission to
            or from the site, (5) any bugs, viruses, trojan horses, or the like
            which may be transmitted to or through the site by any third party,
            and/or (6) any errors or omissions in any content and materials or
            for any loss or damage of any kind incurred as a result of the use
            of any content posted, transmitted, or otherwise made available via
            the site. We do not warrant, endorse, guarantee, or assume
            responsibility for any product or service advertised or offered by a
            third party through the site, any hyperlinked website, or any
            website or mobile application featured in any banner or other
            advertising, and we will not be a party to or in any way be
            responsible for monitoring any transaction between you and any
            third-party providers of products or services. As with the purchase
            of a product or service through any medium or in any environment,
            you should use your best judgment and exercise caution where
            appropriate.
          </p>
          <br />
          <br />
          <p>
            <strong>17. LIMITATIONS AND EXCLUSIONS</strong>
          </p>
          <p>
            (1) The Company makes no guarantee that any specific certifications
            or skills will be granted as a result of the purchase of
            products/services made available through the Platform, and also
            disclaims any financial or monetary outcome or benefit.
          </p>
          <p>
            (2) The Company’s decisions regarding the Platform are final and the
            same policy shall apply with respect to the Products marketed, and
            the Company shall not be liable for the actions or inactions of any
            third party service providers, including uses in connection with the
            delivery of the Platform, programs, courses and distribution of
            documentation.
          </p>
          <p>
            (3) The Company shall not be liable to the Customer for any: (a)
            loss of profit or benefit, direct or indirect; (b) loss of chance or
            opportunity; (c) loss of data; or (d) any direct or consequential
            loss which may be incurred by the Customer.
          </p>
          <p>
            (4) If material or information delivered in the form of digital
            content of any kind affects a device or digital content belonging to
            the Customer, and the Customer does not have the necessary
            appropriate security measures in place, the Company shall not be
            liable in any way.
          </p>
          <p>
            (5) The Customer is responsible for any other applicable additional
            costs, including mobile costs, such as mobile internet costs or
            internet costs at the location where you access the Platform or
            other related costs.
          </p>
          <p>
            (6) Products purchased from the Platform by the Customer do not
            guarantee any financial, commercial or any other kind of result, and
            by simply purchasing it the Company cannot be held liable for the
            future performance of any kind of the Customer’s business.
          </p>
          <p>
            (7) The Company cannot be held liable for reasons relating to the
            financial and/or commercial performance of the Customer subsequent
            to the purchase of the Products/services made available through the
            Platform, the Customer being the only one able to decide how he/she
            chooses to use the information made available by the Company through
            the Products sold.
          </p>
          <br />
          <br />
          <p>
            <strong>18. FORCE MAJEURE</strong>
          </p>
          <p>
            (1) Neither the Company nor the Customer shall be liable for
            non-performance of contractual obligations if such non-performance
            in due time and/or properly, in whole or in part, is due to an event
            of force majeure.
          </p>
          <p>
            (2) If within 15 (fifteen) days from the date of the occurrence of
            the event of force majeure, the such event does not cease, either of
            the Company and the Customer shall be entitled to notify the other
            party of the termination of the Contract as of right without either
            party being entitled to claim damages from the other party.
          </p>
          <br />
          <br />
          <p>
            <strong> 19. INDEMNIFICATION</strong>
          </p>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Site; (2) breach of these Terms of Use; (3) any breach of
            your representations and warranties set forth in these Terms of Use;
            (4) your violation of the rights of a third party, including but not
            limited to intellectual property rights; or (5) any overt harmful
            act toward any other user of the Site with whom you connected via
            the Site. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
          <br />
          <br />
          <p>
            <strong>20. USER DATA</strong>
          </p>
          <p>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </p>
          <br />
          <br />
          <p>
            <strong>
              21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </strong>
          </p>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email, and on the Site, satisfy any legal
            requirement that such communication is in writing. You hereby agree
            to the use of electronic signatures, contracts, orders, and other
            records, and to the electronic delivery of notices, policies, and
            records of transactions initiated or completed by us or via the
            site. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
          <br />
          <br />
          <p>
            <strong>22. MISCELLANEOUS</strong>
          </p>
          <p>
            These Terms of Use and any policies or operating rules posted by us
            on the Site or with respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
            <br />
            Affiliate Program Rules and Terms:
            <br />
            Welcome to our affiliate program! By participating in our program,
            you agree to the following terms and conditions. Our affiliate
            program allows you to earn commissions by promoting our products or
            services through your unique affiliate links. Here are some basic
            rules and terms that govern your participation:
            <ol>
              <li>
                <p>
                  Eligibility: To become an affiliate, you must be at least 18
                  years old and agree to abide by these terms and conditions.
                  You must also have a valid website, blog, or social media
                  platform where you can promote our products effectively.
                </p>
              </li>
              <li>
                <p>
                  Application and Approval: To join our program, you need to
                  complete the affiliate application form accurately and provide
                  any necessary information. We reserve the right to approve or
                  reject applications at our discretion. Once approved, you will
                  receive access to your affiliate account and unique affiliate
                  links.
                </p>
              </li>
              <li>
                <p>
                  Promotional Activities: As an affiliate, you are encouraged to
                  promote our products or services using ethical and legal
                  marketing techniques. You may use approved marketing materials
                  provided by us or create your own, as long as they comply with
                  our branding guidelines.
                </p>
              </li>
              <li>
                <p>
                  Commissions and Payouts: You will earn a commission for each
                  qualified sale generated through your affiliate links. The
                  commission rate and payout structure will be outlined in your
                  affiliate account. Commissions are typically paid on a monthly
                  basis, subject to a minimum payout threshold and any
                  applicable payment processing fees.
                </p>
              </li>
              <li>
                <p>
                  Order Processing and Tracking: We will be responsible for
                  processing and fulfilling orders placed through your affiliate
                  links. It is essential to ensure that your affiliate links are
                  properly formatted and accurately tracked to receive
                  commissions for qualifying sales.
                </p>
              </li>
              <li>
                <p>
                  Prohibited Activities: Certain activities are strictly
                  prohibited within our affiliate program. These include but are
                  not limited to: engaging in spamming, false advertising,
                  trademark infringement, cookie stuffing, or any other
                  unethical practices. Violation of these rules may result in
                  immediate termination of your affiliate account.
                </p>
              </li>
              <li>
                <p>
                  Relationship: This agreement does not create an
                  employer-employee relationship between you and us. As an
                  affiliate, you are an independent contractor responsible for
                  your own taxes and legal obligations arising from
                  participating in our program.
                </p>
              </li>
              <li>
                <p>
                  Modification and Termination: We reserve the right to modify,
                  suspend, or terminate the affiliate program at any time
                  without prior notice. Similarly, you may terminate your
                  participation in the program at any time by providing written
                  notice.
                </p>
              </li>
            </ol>
            <p>
              By participating in our affiliate program, you acknowledge and
              agree to be bound by these rules and terms. We may update or
              modify these terms from time to time, and it is your
              responsibility to review them regularly. If you have any questions
              or concerns, please contact our affiliate support team for
              assistance.{" "}
            </p>
          </p>
          <br />
          <br />
          <p>
            <strong>CONTACT US</strong>
          </p>
          <p>
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us
            at:co*****@sl*****.com{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
