import React from "react";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const RefundPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Refund Policy - | Slayhop.com</title>
      </Helmet>
      <section className="section refund">
        <div className="container">
          <div className="heading">
            <span>Last Updated: 21.03.2023</span>
            <h2>Refund Policy</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="content">
          <p>
            At <strong>SlayHop.com</strong>., we strive to provide an
            outstanding software AI solution designed to help creators go viral
            on TikTok. We value our customers’ satisfaction and have crafted a
            refund policy that aligns with industry standards while taking into
            consideration the unique nature of our services.
          </p>

          <strong>
            <p>1. General Refund Policy</p>
          </strong>
          <p>
            Please note that all charges for in-app purchases are generally
            nonrefundable, and there are no refunds or credits for partially
            used periods. Our services are designed to provide value on an
            ongoing basis, and we encourage customers to utilize the full
            potential of our software throughout their subscription period.
          </p>

          <p>2. Exceptions</p>
          <p>
            While our standard policy is that all in-app purchases are
            nonrefundable, we may make an exception in certain cases:
          </p>
          <p>
            a) If a refund for a SlayHop.com subscription offering is requested
            within 14 days of the transaction date.
          </p>
          <p>
            b) If the laws applicable in your jurisdiction provide for refunds.
          </p>
          <p>
            Please refer to our Terms of Use for more information on our refund
            policy and exceptions.
          </p>
          <p>3. Requesting a Refund</p>
          <p>
            If you believe you are eligible for a refund based on the exceptions
            listed above, please contact our support team at{" "}
            <a href="mailto:support@slayhop.com">support@slayhop.com</a>.
            Provide your transaction details, reason for the refund request, and
            any relevant information to help us assess your case.
          </p>
          <p>4. Processing Refunds</p>
          <p>
            If your refund request is approved, we will initiate the refund
            process. Refunds are typically processed within 7-10 business days
            and will be credited to your original payment method. Please note
            that processing times may vary depending on your financial
            institution.
          </p>
          <p>5. Changes to the Refund Policy</p>
          <p>
            SlayHop.com reserves the right to update or modify this refund
            policy at any time. We will notify you of any significant changes by
            posting an update on our website or by sending an email
            notification. Your continued use of our software app after any
            changes to the refund policy have been implemented constitutes your
            acceptance of the revised policy.
          </p>
          <p>6. Contact Information</p>
          <p>
            If you have any questions about our refund policy or would like to
            submit a refund request, please contact our support team at{" "}
            <a href="mailto:support@slayhop.com">support@slayhop.com</a>.
          </p>
          <p>
            Thank you for choosing SlayHop.com to help you grow your TikTok
            presence. We are committed to providing exceptional service and
            value to our customers, and we appreciate your trust in us.
          </p>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
