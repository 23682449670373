import React from "react";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - | Slayhop.com</title>
      </Helmet>
      <section className="section privacy">
        <div className="container">
          <div className="heading">
            <span>Last Updated: 21.03.2023</span>
            <h2>Privacy Policy</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="content">
          <ol className="list">
            <li>
              <strong>General Information</strong>
              <p>
                privacy of your personal information is one of the main concerns
                of Slayhop.com, as your data controller.
              </p>
              <p>
                This document is intended to inform you about the processing of
                your personal data in the context of the use of the website
                www.Slayhop.com.
              </p>
            </li>
            <li>
              <strong>Categories of Personal Data Processed</strong>
              <p>
                If you are a visitor to the Site, Slayhop.com will process your
                personal data that you provide directly in the context of your
                use of the Site, such as monitoring data. users.
              </p>
            </li>
            <li>
              <strong>Purposes and grounds for processing</strong>
              <p>
                If you are a visitor to the Site, Slayhop.com processes your
                personal data as follows:
              </p>
              <ul>
                <li>
                  <span>
                    to resolve complaints, grievances and to monitor traffic and
                    improve your experience with the Site.
                    <br />
                    Rationale: The processing of your data for this purpose is
                    based on the legitimate interest of Slayhop.com to ensure
                    the proper functioning of the Site, as well as to
                    permanently improve the experience of visitors to the Site,
                    including by resolving various comments, questions or
                    complaints. .
                    <br />
                    Providing your data for this purpose is voluntary. Refusal
                    to provide data for this purpose will not have a negative
                    effect on you.
                  </span>
                  <ol>
                    <li>
                      <strong>How long we process your data</strong>
                      <p>
                        As a matter of principle, Slayhop.com will process your
                        personal data for as long as is necessary to accomplish
                        the processing purposes mentioned above.
                      </p>
                    </li>
                    <li>
                      <strong>Disclosure of personal data</strong>
                      <p>
                        In order to fulfill the purposes of processing,
                        Slayhop.com may disclose your data to partners, third
                        parties or entities that support Slayhop.com in carrying
                        out its activity through the Site (e.g. courier, IT
                        service providers), or to the central / local public
                        authorities, in the following exemplary cases listed:
                      </p>
                    </li>
                  </ol>
                  <ul>
                    <li>
                      <span> for the administration of the Site;</span>
                    </li>
                    <li>
                      <span>
                        for the maintenance, personalization and improvement of
                        the Site and the services provided through it;
                      </span>
                    </li>
                    <li>
                      <span>
                        for data analysis, testing and research, monitoring
                        usage and activity trends, developing security features
                        and user authentication;
                      </span>
                    </li>
                    <li>
                      <span>
                        when the disclosure of personal data is provided by law,
                        etc.
                      </span>
                    </li>
                    <li>
                      <strong>Transfer of personal data The personal</strong>{" "}
                      <span>
                        data provided to Slayhop.com may be transferred outside
                        US (United States of America), depends on third parties
                        collaborations.
                      </span>
                    </li>
                    <p>
                      Under the conditions provided by the legislation on the
                      processing of personal data, as data subjects, you benefit
                      from the following rights:
                    </p>
                    <li>
                      <span>
                        the right to information, respectively the right to
                        receive details on the processing activities carried out
                        by Slayhop.com , as described in this document;
                      </span>
                    </li>
                    <li>
                      <span>
                        the right to access data, namely the right to obtain
                        confirmation from Slayhop.com regarding the processing
                        of personal data, as well as details on the processing
                        activities such as the way in which the data are
                        processed, the purpose for which the processing is
                        carried out, recipients or categories of data
                        recipients, etc .;
                      </span>
                    </li>
                    <li>
                      <span>
                        the right to rectification, respectively the right to
                        obtain the correction, without justified delays, by
                        Slayhop.com of inaccurate / unjustified personal data,
                        as well as the completion of incomplete data; The
                        rectification / completion will be communicated to each
                        recipient to whom the data were transmitted, unless this
                        proves impossible or involves disproportionate effort.
                      </span>
                    </li>
                    <li>
                      <span>
                        the right to delete data, without undue delay, (“right
                        to be forgotten”), if one of the following reasons
                        applies:
                      </span>
                      <ul>
                        <li>
                          <span>
                            they are no longer necessary for the purpose for
                            which they were collected or processed;
                          </span>
                        </li>
                        <li>
                          <span>
                            if the consent is withdrawn and there is no other
                            legal basis for the processing;
                          </span>
                        </li>
                        <li>
                          <span>
                            if the data subject objects to the processing and
                            there are no legitimate reasons prevailing;
                          </span>
                        </li>
                        <li>
                          <span>
                            if personal data have been processed illegally;
                          </span>
                        </li>
                        <li>
                          <span>
                            if personal data must be deleted in order to comply
                            with a legal obligation;
                          </span>
                        </li>
                        <li>
                          <span>
                            personal data have been collected in connection with
                            the provision of information society services in
                            accordance with Union or national law under the
                            control of the controller.
                          </span>
                        </li>
                      </ul>
                      <p>
                        It is possible that, following the request to delete the
                        data, Slayhop.com will anonymize this data (thus
                        depriving it of its personal character) and continue in
                        these conditions the processing for statistical
                        purposes;
                      </p>
                    </li>
                    <li>
                      <span>
                        the right to restrict the processing to the extent that:
                      </span>
                    </li>
                    <ul>
                      <li>
                        <span>
                          the person disputes the accuracy of the data, for a
                          period that allows us to verify the correctness of the
                          data;
                        </span>
                      </li>
                      <li>
                        <span>
                          the processing is illegal and the data subject opposes
                          the deletion of personal data, requesting instead that
                          their use be restricted;
                        </span>
                      </li>
                      <li>
                        <span>
                          the controller no longer needs the personal data for
                          the purpose of processing, but the data subject
                          requests them for the establishment, exercise or
                          defense of a right in court; or
                        </span>
                      </li>
                      <li>
                        <span>
                          the data subject has objected to the processing (other
                          than direct marketing), for the period during which it
                          is verified whether the legitimate rights of the
                          controller prevail over those of the data subject.
                        </span>
                      </li>
                    </ul>
                    <li>
                      <span>
                        the right to data portability, respectively (i) the
                        right to receive personal data in a structured, commonly
                        used and easy-to-read format, and (ii) the right to have
                        such data transmitted by Slayhop.com to another data
                        controller, insofar as the conditions provided by law
                        are met;
                      </span>
                    </li>
                    <li>
                      <span>
                        the right to object – in respect of processing
                        activities may be exercised by submitting a request as
                        indicated below;
                      </span>
                      <ul>
                        <li>
                          <span>
                            at any time, for reasons related to the particular
                            situation of the data subject, that the data
                            concerning him / her be processed in the legitimate
                            interest of Slayhop.com or in the public interest,
                            except in cases where slayhop.com can demonstrate
                            that it has legitimate and compelling reasons
                            justifying the processing and prevailing over the
                            interests, rights and freedoms of data subjects or
                            that the purpose is to establish, exercise or defend
                            a right in court;
                          </span>
                        </li>
                        <li>
                          <span>
                            at any time, free of charge and without any
                            justification, that the data concerning it be
                            processed for the purpose of direct marketing.
                          </span>
                        </li>
                      </ul>
                      <li>
                        <span>
                          the right not to be subject to an automatic individual
                          decision, ie the right not to be subject to a decision
                          taken solely on the basis of automatic processing
                          activities, including profiling, which produces legal
                          effects concerning the data subject or similarly
                          affects -a significant measure;
                        </span>
                      </li>
                      <li>
                        <span>
                          the right to apply to the National Authority for the
                          Processing of Personal Data or to the competent
                          courts, to the extent deemed necessary.
                        </span>
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                If you have any further questions regarding the way personal
                data is processed and to exercise your rights mentioned above,
                please contact us at: co*****@Sl*****.com.
              </p>
              <br />
              <br />
              <p>This website uses cookies.</p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Privacy;
