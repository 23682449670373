import React from "react";
import BlogHero from "./sections/blogHero";
import Blogs from "./sections/blogs";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Our Blog - | Slayhop.com</title>
      </Helmet>
      <BlogHero />
      <Blogs />
    </>
  );
};

export default Blog;
