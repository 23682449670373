import React from "react";
import './NotFound.scss'

const NotFound = () => {
  return (
    <section className="section not-found">
      <div className="container">
        <h3>Page Not Found</h3>
      </div>
    </section>
  );
};

export default NotFound;
