import React, { useState } from 'react';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../common/primaryButton';
import { logo } from '../../common/images';
import { useMediaQuery } from 'react-responsive';
import { scrollToTop } from '../../common/ScrollToTop';
import { scrollToSection } from '../../common/ScrollToSection';

const Header = ({ setOverlay }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // close menu
  const handleMenuClick = () => {
    if (isTabletOrMobile) {
      setShowMenu(!showMenu);
      if (!showMenu) {
        setOverlay(true);
        document.body.classList.add('hide-scroll');
      } else {
        setOverlay(false);
        document.body.classList.remove('hide-scroll');
        scrollToTop();
      }
    } else {
      scrollToTop();
    }
  };

  const handleWaitingListClick = () => {
    if (isTabletOrMobile) {
      navigate('/');
      setShowMenu(!showMenu);
      setOverlay(false);
      document.body.classList.remove('hide-scroll');
    } else {
      navigate('/');
    }
    scrollToSection('waitingList');
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div
        className={`hamburger-icon ${showMenu ? 'animate' : ''}`}
        onClick={handleMenuClick}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`nav-menu ${showMenu ? 'mbl-menu' : ''}`}>
        <ul>
          {showMenu ? (
            <li className="logo">
              <Link to="/" onClick={handleMenuClick}>
                <img src={logo} alt="logo" />
              </Link>
            </li>
          ) : (
            ''
          )}
          <li>
            <Link onClick={handleMenuClick} to="/">
              Our App
            </Link>
          </li>
          <li>
            <Link onClick={handleMenuClick} to="/contact-us">
              Contact Us
            </Link>
          </li>
          <li>
            <Link onClick={handleMenuClick} to="/blog">
              Blog
            </Link>
          </li>
          <li onClick={handleWaitingListClick}>
            <PrimaryButton
              primaryBtnText="Join waitlist"
              primaryBtnIcon={
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.3335 8H12.6668"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 3.33333L12.6667 8L8 12.6667"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              }
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
