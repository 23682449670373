import React from "react";
import "./style.scss";

const SectionHeading = ({
  title,
  desc,
  button,
  maxWidth,
  className,
  betaText,
}) => {
  return (
    <div
      style={{ maxWidth: maxWidth ? maxWidth : "" }}
      className={`section-heading ${className ? className : ""}`}
    >
      <h2>{title}</h2>
      <p>{desc}</p>
      {button ? button : ""}
      {betaText ? <span>{betaText}</span> : ""}
    </div>
  );
};

export default SectionHeading;
