import React from "react";
import "./style.scss";

const BlogHero = () => {
  return (
    <section className="section blog-hero">
      <div className="container">
        <div className="content">
          <span>Our blog</span>
          <h2>The latest writings from our team</h2>
          <p>
            The latest industry news, interviews, technologies, and resources
          </p>
        </div>
      </div>
    </section>
  );
};

export default BlogHero;
