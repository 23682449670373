import React from "react";
import "./style.scss";
import { Helmet } from "react-helmet-async";

const CookiesPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Cookies Policy - | Slayhop.com</title>
      </Helmet>
      <section className="section cookies">
        <div className="container">
          <div className="heading">
            <span>Last Updated: 21.03.2023</span>
            <h2>Cookies Policy</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="content">
          <p>
            <strong>Introduction</strong>
          </p>
          <p>
            This Cookie Policy applies to all users of Www.Slayhop.com The
            following information is intended to inform users of this website
            about the placement, use and administration of cookies by
            Slayhop.com in the context of users browsing this website.
          </p>
          <p>What are cookies?</p>
          <p>
            We use the term “cookies” to refer to cookies and similar
            technologies through which information can be collected
            automatically.
          </p>
          <p>
            An “Internet Cookie” (also known as a “browser cookie” or “HTTP
            cookie” or “cookie”) is a small file, consisting of letters and
            numbers, that will be stored on your computer, mobile terminal, or
            other equipment of a user through which the Internet is accessed.
          </p>
          <p>
            Cookies are installed by request from a web server to a browser (eg
            Internet Explorer, Firefox, Chrome). Once installed, cookies have a
            definite lifespan, remaining “passive” in the sense that they do not
            contain software, viruses or spyware and will not access the
            information on the hard drive of the user on whose equipment they
            were installed.
          </p>
          <p>A cookie consists of two parts:</p>
          <p>The name of the cookie; and the content or value of the cookie.</p>
          <p>
            From a technical point of view, only the web server that sent the
            cookie can access it again when a user returns to the web page
            associated with that web server.
          </p>
          <p>The purpose for which cookies are used through this website:</p>
          <p>
            Cookies are used to provide users of this website with a better
            browsing experience and services tailored to the needs and interests
            of each user, namely:
          </p>
          <p>
            improving the use of this website, including by identifying any
            errors that occur during the visit / use by users;
          </p>
          <p>
            providing anonymous statistics on how this website is used to
            SLAYHOP.COM, as the owner of this website;
          </p>
          <p>
            anticipation of any goods that will be made available to users in
            the future through this website, depending on the services /
            products accessed.
          </p>
          <p>
            Based on the feedback sent by cookies regarding the use of this
            website, SLAYHOP.COM may take measures to make this website more
            efficient and more accessible to users.
          </p>
          <p>
            Thus, the use of cookies allows the storage of certain settings /
            preferences set by the users of this website.
          </p>
          <p>What is the lifetime of cookies?</p>
          <p>
            The lifetime of cookies can vary significantly, depending on the
            purpose for which they are placed. There are the following
            categories of cookies that also determine their lifetime:
          </p>
          <p>
            Sessioncookies – A “session cookie” is a cookie that is
            automatically deleted when the user closes their browser.
          </p>
          <p>
            Persistent or fixedcookies – A “persistent” or “fixed” cookie is a
            cookie that remains stored in the user’s terminal until it reaches a
            certain expiration date (which may be in a few minutes, days or
            several years in the future) or until when deleted by the user at
            any time through the browser settings.
          </p>
          <p>What are cookies placed by third parties?</p>
          <p>
            Certain sections of the content on the website may be provided by
            third parties, ie not by SLAYHOP.COM, in which case these cookies
            are called third party cookies. .
          </p>
          <p>
            Third-party cookie providers must also comply with the data
            protection rules and Privacy Policy available on this website.
          </p>
          <p>
            These cookies may come from the following third parties: Google
            Analytics, Google AdSense, Facebook, Sendpulse, InternetCorp.
          </p>
          <p>What cookies are used through this website:</p>
          <p>
            By using / visiting the website the following cookies can be placed:
          </p>
          <p>Performance cookies of the website;</p>
          <p>User analysis cookies;</p>
          <p>Geotargetting cookies;</p>
          <p>Registration cookies;</p>
          <p>Advertising cookies;</p>
          <p>Advertising provider cookies;</p>
          <p>Performance</p>
          <p>
            cookies This type of cookie stores the user’s preferences for this
            webpage, so resetting the preferences when you visit the webpage
            later is no longer necessary.
          </p>
          <p>User analysis</p>
          <p>
            cookies These cookies inform us if a certain user of the website has
            visited / used this website before. These cookies are used for
            statistical purposes only.
          </p>
          <p>Geotargetting</p>
          <p>
            cookies These cookies are used by software that determines the
            country of origin of the user of the website. The same ads will be
            received regardless of the language selected.
          </p>
          <p>Cookies for registration</p>
          <p>
            When you register on this site, cookies are generated that store
            this process. The servers use these cookies to show us the account
            with which you are registered. Also, the use of these cookies allows
            us to associate any comment posted on the website with the username
            of the account used. If the “keep me logged in” option is not
            selected, these cookies will be automatically deleted at the end of
            the browsing session.
          </p>
          <p>Advertising</p>
          <p>
            cookies These cookies allow a user to find out when a user is
            viewing an online advertisement, its type and the time elapsed since
            the respective advertising message was viewed. As such, such cookies
            are used to target online advertising. These cookies are anonymous,
            storing information about the content viewed, not about users.
          </p>
          <p>
            What kind of information is stored and accessed through cookies?
          </p>
          <p>
            Cookies store information in a small text file that allows browser
            recognition. This webpage recognizes the browser until cookies
            expire or are deleted.
          </p>
          <p>Customize your browser settings for cookies</p>
          <p>
            If the use of cookies is not annoying and the computer or technical
            equipment used to browse this website is used only by you, long
            expiration dates may be set. for storing your browsing history.
          </p>
          <p>
            If the computer or technical equipment used to browse this webpage
            is used by more than one person, the setting for deleting individual
            browsing data each time the browser is closed may be considered.
          </p>
          <p>How can cookies be stopped?</p>
          <p>
            Disabling and refusing to receive cookies can make this website
            difficult to visit, leading to limitations in its use.
          </p>
          <p>
            Users can configure their browser to reject cookies or to accept
            cookies from a specific website.
          </p>
          <p>
            All modern browsers offer the ability to change cookie settings.
            These settings can be accessed, as a rule, in the “options” section
            or in the “preferences” menu of your browser.
          </p>
          <p>
            However, refusing or disabling cookies does not mean that you will
            no longer receive online advertising – only that it will not be
            tailored to your preferences and interests, as evidenced by your
            browsing behavior.
          </p>
          <p>
            However, refusing or disabling cookies does not mean that you will
            no longer receive online advertising – only that it will not be
            tailored to your preferences and interests, as evidenced by your
            browsing behavior.
          </p>
          <p>
            To understand these settings, the following links may be useful:
          </p>
          <p>Cookie Settings In Internet Explorer</p>
          <p>Cookie Settings In Firefox</p>
          <p>Cookie Settings In Chrome</p>
          <p>Cookie Settings In Safari</p>
          <p>
            For any additional questions about how cookies are used through this
            website, please contact co*****@Sl*****.com
          </p>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicy;
