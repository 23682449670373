import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import PrimaryButton from '../../../../../common/primaryButton';
import { scrollToTop } from '../../../../../common/ScrollToTop';
import { errorNotification } from '../../../../../common/notification-popup';
import { config } from '../../../../../config';
import useRecaptcha from '../../../../../services/useRecaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const schema = yup
  .object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().required('Email is required').email('Invalid email'),
    phone: yup.string().required('Phone number is required'),
    message: yup.string().required('Message is required'),
  })
  .required();

const ContactPageForm = ({ btnText, noIcon }) => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setLoad(true);
  }, []);

  const onSubmit = async (data) => {
    if (!capchaToken) {
      errorNotification('Failed to get reCAPTCHA token');
      return;
    }
    setLoading(true);
    axios
      .post(`${config.apiUrl}/contact-us`, {
        ...data,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const renderBtntext = () => {
    if (loading) {
      return 'loading...';
    }
    if (btnText) {
      return btnText;
    } else {
      return 'Join waitlist';
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="name">
        <div>
          <label className="form-label">First name *</label>
          <input
            type="text"
            className={`form-control ${
              errors?.first_name?.message && 'error-input'
            }`}
            placeholder="First name"
            {...register('first_name')}
          />
          {errors?.first_name?.message && (
            <span className="error">{errors?.first_name?.message}</span>
          )}
        </div>
        <div>
          <label className="form-label">Last name *</label>
          <input
            type="text"
            className={`form-control ${
              errors?.last_name?.message && 'error-input'
            }`}
            placeholder="Last name"
            {...register('last_name')}
          />
          {errors?.last_name?.message && (
            <span className="error">{errors?.last_name?.message}</span>
          )}
        </div>
      </div>
      <div className="email">
        <label className="form-label">Email *</label>
        <input
          type="email"
          className={`form-control ${errors?.email?.message && 'error-input'}`}
          placeholder="you@company.com"
          {...register('email')}
        />
        {errors?.email?.message && (
          <span className="error">{errors?.email?.message}</span>
        )}
      </div>
      <div className="select-phone">
        <label className="form-label">Phone *</label>
        <div
          className={`form-group-phone ${
            errors?.phone?.message && 'error-input'
          }`}
        >
          <select className="form-control">
            <option defaultValue>US</option>
          </select>
          <input
            type="phone"
            className="form-control"
            placeholder="+1 (555) 000-0000"
            {...register('phone')}
          />
        </div>
        {errors?.phone?.message && (
          <span className="error">{errors?.phone?.message}</span>
        )}
      </div>
      <div className="message">
        <label className="form-label">Message *</label>
        <textarea
          className={`form-control ${
            errors?.message?.message && 'error-input'
          }`}
          name=""
          id=""
          placeholder="Leave us a message..."
          {...register('message')}
        />
        {errors?.phone?.message && (
          <span className="error">{errors?.message?.message}</span>
        )}
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="exampleCheck1"
        />
        <label className="form-check-label" htmlFor="exampleCheck1">
          You agree to our friendly{' '}
          <Link onClick={scrollToTop} to="/privacy-policy">
            privacy policy
          </Link>
          .
        </label>
      </div>
      {load ? (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.captchaKey}
          onChange={handleRecaptcha}
        />
      ) : null}
      <PrimaryButton
        type={'submit'}
        isLoading={loading}
        noIcon={noIcon}
        primaryBtnText={renderBtntext()}
        primaryBtnIcon={
          <>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.3335 8H12.6668"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 3.33333L12.6667 8L8 12.6667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        }
      />
    </form>
  );
};

export default ContactPageForm;
