import React from "react";
import "./style.scss";
import ContactPageForm from "./contactForm";

const ContactHero = () => {
  return (
    <section className="section contact-hero">
      <div className="container">
        <div className="card">
          <div className="heading">
            <h5>Contact us</h5>
            <h3>We'd like to hear from you</h3>
          </div>
          <ContactPageForm />
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
