/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { getArticles } from '../../../../services/blogs';
import { config } from '../../../../config';
import moment from 'moment';
import { scrollToTop } from '../../../../common/ScrollToTop';
import BlogsLoader from './BlogsLoader';
import Pagination from './pagination/Pagination';

const Blogs = () => {
  const [blogList, setBlogList] = useState([]);
  const [blogFilteredList, setBlogFilteredList] = useState([]);
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const handleFetch = async () => {
    const { status, res } = await getArticles(page, pageSize);
    setIsLoader(false);
    if (status) {
      setBlogList(res.data);
      setBlogFilteredList(res.data);
      // console.log(res.data);

      const uniqueCategories = [
        ...new Set(
          res.data.map(
            (blog) =>
              blog?.attributes?.social_categories?.data?.[0]?.attributes?.name
          )
        ),
      ];
      setCategories(['View all', ...uniqueCategories]);
      setTotal(10);
    }
  };

  const handleFilter = (c_name) => {
    if (c_name !== 'View all') {
      let tempList = [...blogList];

      tempList = tempList.filter(
        (item) =>
          item?.attributes?.social_categories?.data?.[0]?.attributes?.name ===
          c_name
      );

      setBlogFilteredList([...tempList]);
    } else {
      setBlogFilteredList(blogList);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [pageSize, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <section className="section Articles">
      <div className="container">
        <div className="article-type">
          <div className="article-tab w-100">
            <Tabs>
              <div className="flex-content">
                <TabList>
                  {categories?.map((c, index) => (
                    <Tab key={index} onClick={() => handleFilter(c)}>
                      {c}
                    </Tab>
                  ))}
                </TabList>
                <select className="most-recent">
                  <option>Most recent</option>
                  <option>Select</option>
                </select>
              </div>
              {isLoader ? (
                <BlogsLoader />
              ) : (
                <div
                  className="Blog-List blog-cards"
                  style={{ marginTop: '40px' }}
                >
                  {blogFilteredList?.map((item) => (
                    <Link
                      to={`/blog-detail/${item?.id}`}
                      onClick={scrollToTop}
                      className="card"
                      key={item?.id}
                      state={{
                        category:
                          item?.attributes?.fintech_categories?.data?.[0]
                            ?.attributes?.name,
                      }}
                    >
                      <div className="img">
                        <img
                          src={`${config.imgBaseUrl}${item?.attributes?.display_image?.data?.attributes?.url}`}
                          className="card-img-top"
                          alt="..."
                        />
                      </div>
                      <div className="card-content">
                        <label>
                          {
                            item?.attributes?.fintech_categories?.data?.[0]
                              ?.attributes?.name
                          }
                        </label>
                        <div className="headingicon">
                          <h4>
                            {item?.attributes?.title.slice(0, 30)}
                            {item?.attributes?.title?.length > 30 ? '...' : ''}
                          </h4>
                          <div style={{ marginTop: '6px' }}>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 11L11 1M11 1H1M11 1V11"
                                stroke="#F5F5F6"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <p>
                          {item?.attributes?.description.slice(0, 80)}
                          {item?.attributes?.description?.length > 80
                            ? '...'
                            : ''}
                        </p>
                        <div className="blogpostinfo">
                          {/* <div className="avatar">
                            <img
                              src={`${config.imgBaseUrl}${
                                item?.attributes?.creator?.data?.attributes
                                  ?.creator_image?.data?.attributes?.url ||
                                `${config.imgBaseUrl}/uploads/creator_logo_50e0b92492.png`
                              }`}
                              alt="creator_image"
                              onError={(e) => {
                                e.currentTarget.src = `${config.imgBaseUrl}/uploads/creator_logo_50e0b92492.png`;
                              }}
                            />
                          </div> */}
                          <div className="text">
                            {/* <h4>
                              {' '}
                              {
                                item?.attributes?.creator?.data?.attributes
                                  ?.creator_name
                              }
                            </h4> */}
                            <p>
                              {moment(item?.attributes?.publishedAt).format(
                                'DD MMM YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </Tabs>
          </div>
        </div>
        <Pagination
          total={total}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
        />
      </div>
    </section>
  );
};

export default Blogs;
