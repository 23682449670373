import React from 'react';
import './style.scss';
import { pin } from '../../../../common/images';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '10px',
};

const center = {
  lat: 36.10345803846022,
  lng: -115.2965866731205,
};

const ContactMap = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBCWtGbgsi8iNjTLixH1adK-h_qS-CMokc',
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <section className="section map-section">
      <div className="container">
        <div className="ggl-map">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={10}
            center={center}
          >
            <Marker position={center} />
          </GoogleMap>
        </div>
        <div className="contact-info">
          <div className="info">
            <img src={pin} alt="pin" />
            <h4>SLAYHOP INC</h4>
            <h4>4730 S.FORT APACHE RD SUITE 300</h4>
            <h4>Las Vegas, Nevada 89147</h4>
            <br />
            <h4>Phone: +1-602-803-2868</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactMap;
